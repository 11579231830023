// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/global-settings/provider.ts"
);
import.meta.hot.lastModified = "1742423112000";
}
// REMIX HMR END

import {
  AdministratorCustomFieldConfigsQuery,
  ChannelCustomFieldConfigsQuery,
  CollectionCustomFieldConfigsQuery,
  CustomerCustomFieldConfigsQuery,
  GlobalSettingsQuery,
  PaymentMethodCustomFieldConfigsQuery,
  ProductCustomFieldConfigsQuery,
  ProductVariantCustomFieldConfigsQuery,
  SellerCustomFieldConfigsQuery,
  SettingsCustomFieldConfigsQuery,
  SettingsQuery,
  UpdateGlobalSettingsInput,
  UpdateGlobalSettingsMutation,
} from '~admin/generated/graphql'
import { QueryOptions, sdk } from '~admin/graphqlWrapper'

export const adminSettings = (
  queryOptions?: QueryOptions,
): Promise<SettingsQuery> => {
  return sdk.settings(undefined, queryOptions)
}

export const update = (
  input: UpdateGlobalSettingsInput,
  queryOptions?: QueryOptions,
): Promise<UpdateGlobalSettingsMutation> => {
  return sdk.updateGlobalSettings({ input }, queryOptions)
}

export const settings = (
  queryOptions?: QueryOptions,
): Promise<GlobalSettingsQuery> => {
  return sdk.globalSettings(undefined, queryOptions)
}

export const getPaymentMethodCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<PaymentMethodCustomFieldConfigsQuery> => {
  return sdk.paymentMethodCustomFieldConfigs(undefined, queryOptions)
}

export const getProductCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<ProductCustomFieldConfigsQuery> => {
  return sdk.productCustomFieldConfigs(undefined, queryOptions)
}

export const getProductVariantCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<ProductVariantCustomFieldConfigsQuery> => {
  return sdk.productVariantCustomFieldConfigs(undefined, queryOptions)
}

export const getCollectionCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<CollectionCustomFieldConfigsQuery> => {
  return sdk.collectionCustomFieldConfigs(undefined, queryOptions)
}

export const getCustomerCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<CustomerCustomFieldConfigsQuery> => {
  return sdk.customerCustomFieldConfigs(undefined, queryOptions)
}

export const getAdministratorCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<AdministratorCustomFieldConfigsQuery> => {
  return sdk.administratorCustomFieldConfigs(undefined, queryOptions)
}

export const getChannelCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<ChannelCustomFieldConfigsQuery> => {
  return sdk.channelCustomFieldConfigs(undefined, queryOptions)
}

export const getSellerCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<SellerCustomFieldConfigsQuery> => {
  return sdk.sellerCustomFieldConfigs(undefined, queryOptions)
}

export const getSettingsCustomFieldSettings = (
  queryOptions?: QueryOptions,
): Promise<SettingsCustomFieldConfigsQuery> => {
  return sdk.settingsCustomFieldConfigs(undefined, queryOptions)
}